<template>
  <v-card>
    <exam-component v-if="currentExam" :exam="currentExam"></exam-component>
  </v-card>
</template>

<script>
import ExamComponent from "./ExamComponent";
// import request from "./common/HttpRequests.js";
require("../../assets/css/neo.css");
export default {
  data() {
    return {
      currentExam: null
    };
  },
  methods: {
    init() {
      this.currentExam = this.$route.params.examId;
    }
  },
  components: {
    ExamComponent
  },
  mounted: function() {
    this.init();
  }
};
</script>

<style scoped>
</style>
